// See: https://www.gatsbyjs.org/docs/browser-apis/

import 'video-react/dist/video-react.css';

import 'semantic-ui-css/components/reset.css';
import 'semantic-ui-css/components/site.css';
import 'semantic-ui-css/components/button.css';
import 'semantic-ui-css/components/container.css';
import 'semantic-ui-css/components/grid.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/image.css';
import 'semantic-ui-css/components/modal.css';

import './src/semantic-ui.overrides.css';
import './src/container-ket.css';
import './src/fonts/aleo.css';
import './src/fonts/myriad.css';
import './src/fonts/open-sans.css';
import './src/app.css';

import './src/object.assign.polyfill';
