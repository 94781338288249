// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brass-clip-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/brass-clip.js" /* webpackChunkName: "component---src-pages-brass-clip-js" */),
  "component---src-pages-brass-deflector-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/brass-deflector.js" /* webpackChunkName: "component---src-pages-brass-deflector-js" */),
  "component---src-pages-contact-us-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-rad-stuff-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/other-rad-stuff.js" /* webpackChunkName: "component---src-pages-other-rad-stuff-js" */),
  "component---src-pages-products-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-setup-js": () => import("/Users/joe/Sites/weiss/ket/src/pages/setup.js" /* webpackChunkName: "component---src-pages-setup-js" */)
}

